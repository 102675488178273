<template>
  <v-navigation-drawer
    id="nav-drawer"
    v-model="show"
    app
    dark
    floating
    persistent
    mobile-breakpoint="991"
    width="260"
  >
    <v-img src="./../assets/sidebar.jpg">

      <v-list avatar>
        <v-list-item>
          <v-list-item-avatar color="white">
            <v-img :src="`${publicPath}favicon.ico`" height="34" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{$appName}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list dense>

        <nav-section v-for="item in items" :key="item.section" :name="item.section" :navItems="item.navItems">
        </nav-section>

      </v-list>

    </v-img>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavDrawer',

  props: {
    items: { type: Array, required: true },
    value: { type: Boolean, required: false, default: true },
  },

  data: () => ({
    // need to get the base URL when using items from the public dir
    publicPath: process.env.BASE_URL,
    searchText: null,
  }),

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  components: {
    NavSection: () => import('@/components/NavSection'),
  },

  methods: {
  },
}
</script>

<style lang="scss">
#nav-drawer {
  background-color: #1e3b3f;
}
</style>
